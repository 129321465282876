<div id="centered-content">
  <table id="main-table">
    <tr>
      <table *ngIf="!hideButtons">
          <tr>
              <select [(ngModel)]="selectedDoctor" (change)="recordDoctor()">
                <option value="-1" disabled selected>Seleccione su nombre</option>
                <option *ngFor="let doctor of doctors" [value]="doctor.id">{{doctor.name}}</option>
              </select>
              <select [(ngModel)]="selectedMonth" (change)="updateGraph()">
                <option *ngFor="let month of months" [value]="month.id">{{month.name}}</option>
              </select>
              <select [(ngModel)]="selectedYear" (change)="updateGraph()">
                <option *ngFor="let year of years" [value]="year">{{year}}</option>
              </select>
              <select [(ngModel)]="selectedPlan" (change)="updateGraph()">
                <option value="-1" disabled selected>Seleccione el Plan</option>
                <option *ngFor="let plan of plans" [value]="plan">{{plan}}</option>
              </select>
              <button (click)="print()">Imprimir</button>
          </tr>
        </table>
    </tr>
    <tr>
      <table id="doctor-info">
        <tr>
          <td>
            {{getCurrentDoctorName()}}
          </td>
          <td class="align-right">
            {{months[selectedMonth].name + " " + selectedYear}}
          </td>
        </tr>
        <tr>
          <td *ngIf="!hideButtons">
            Paciente: <input [(ngModel)]="patientName" type=text/>
          </td>
          <td *ngIf="hideButtons">
            Paciente: {{patientName}}
          </td>
        </tr>
        <tr>
          <td>
            Diabetes y Síndrome Metabolico
          </td>
        </tr>
      </table>
    </tr>
    <tr>
      <div align="right">
        <table class="formatted-cells" id="measurement-times-table" cellspacing="0" cellpadding="0">
          <tr>
            <td class="long-td">Ayuno</td>
            <td class="long-td">Desayuno 2 horas despues</td>
            <td class="long-td">Comida Antes</td>
            <td class="long-td">Comida 2 horas despues</td>
            <td class="long-td">Cena Antes</td>
            <td class="long-td">Cena 2 horas despues</td>
          </tr>
        </table>
      </div>
    </tr>
    <tr>
      <table class="formatted-cells" id="measurement-dates-table" cellspacing="0" cellpadding="0">
        <tr *ngFor="let currDay of daysInGraph">
          <td class="short-td"><b>{{currDay.number}}</b></td>
          <td class="long-td">{{currDay.dayOfWeek}}</td>
          <td class="long-td" id='1-{{currDay.number}}'></td>
          <td class="long-td" id='2-{{currDay.number}}'></td>
          <td class="long-td" id='3-{{currDay.number}}'></td>
          <td class="long-td" id='4-{{currDay.number}}'></td>
          <td class="long-td" id='5-{{currDay.number}}'></td>
          <td class="long-td" id='6-{{currDay.number}}'></td>
        </tr>
        <tr>
        </tr>
      </table>
    </tr>
  </table>
</div>